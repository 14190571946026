<template>
  <div class="w-full max-w-xl mx-auto pt-24 mb-16 px-3">
    <h2 class="text-3xl font-semibold pt-3">About us</h2>

    <p class="mt-5 font-sans font-bold text-grey-dark">Why graduated.io?</p>

    <p class="mt-5 font-sans font-light text-grey-dark">
      We're so happy you stopped by. Please get comfortable and let us help you
      begin your career journey with us.
    </p>

    <p class="mt-4 font-sans font-light text-grey-dark">
      Here at graduated.io we understand that studying in itself is a full-time
      job however, now that you are reaching the end of your study journey (well
      done) we know that beginning the search for your very first full-time job
      can be overwhelming. You may struggle to know where to look for jobs or
      find companies or employers who run graduate programmes to help you get
      started. Well… there is no need to worry! graduated.io is here to help
      guide you in finding your very first full-time role with ease!
    </p>

    <p class="mt-5 font-sans font-light text-grey-dark">
      Not only do we help you in your job search but we have specifically hand
      crafted this site so that you can pick and choose the areas that interest
      you the most and use that data to
      <span class="font-medium">culturally and technically match you</span> with
      the right employer.
    </p>

    <p class="mt-5 font-sans font-light text-grey-dark">
      Our main aim is to allow you to explore a variety of successful companies
      that commit to hiring graduates just like you, and who have solid
      programmes in place for you to track your career growth and continuous
      learning.
    </p>

    <p class="mt-5 font-sans font-light text-grey-dark">
      graduate.io is not just a website, it’s a complete service. We want to
      provide a personalised recruitment experience tailored to you and your
      needs.
    </p>

    <p class="mt-5 font-sans font-light text-grey-dark">
      We know you must be excited to kick start your journey today, so why don’t
      you head over and take a look at our
      <a href="/" class="underline">companies</a> who are currently hiring grads
      today and <span class="font-medium">explore your matches!</span>
    </p>

    <p class="mt-5 font-sans font-light text-grey-dark">
      Are you an <span class="font-medium">employer</span> looking to hire top
      talent in the graduate engineering space? You can
      <a href="/contact-us" class="underline">contact us here</a> to get
      started.
    </p>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {}
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-full max-w-xl mx-auto pt-24 mb-16 px-3" },
      [
        _c("h2", { staticClass: "text-3xl font-semibold pt-3" }, [
          _vm._v("About us")
        ]),
        _c("p", { staticClass: "mt-5 font-sans font-bold text-grey-dark" }, [
          _vm._v("Why graduated.io?")
        ]),
        _c("p", { staticClass: "mt-5 font-sans font-light text-grey-dark" }, [
          _vm._v(
            " We're so happy you stopped by. Please get comfortable and let us help you begin your career journey with us. "
          )
        ]),
        _c("p", { staticClass: "mt-4 font-sans font-light text-grey-dark" }, [
          _vm._v(
            " Here at graduated.io we understand that studying in itself is a full-time job however, now that you are reaching the end of your study journey (well done) we know that beginning the search for your very first full-time job can be overwhelming. You may struggle to know where to look for jobs or find companies or employers who run graduate programmes to help you get started. Well… there is no need to worry! graduated.io is here to help guide you in finding your very first full-time role with ease! "
          )
        ]),
        _c("p", { staticClass: "mt-5 font-sans font-light text-grey-dark" }, [
          _vm._v(
            " Not only do we help you in your job search but we have specifically hand crafted this site so that you can pick and choose the areas that interest you the most and use that data to "
          ),
          _c("span", { staticClass: "font-medium" }, [
            _vm._v("culturally and technically match you")
          ]),
          _vm._v(" with the right employer. ")
        ]),
        _c("p", { staticClass: "mt-5 font-sans font-light text-grey-dark" }, [
          _vm._v(
            " Our main aim is to allow you to explore a variety of successful companies that commit to hiring graduates just like you, and who have solid programmes in place for you to track your career growth and continuous learning. "
          )
        ]),
        _c("p", { staticClass: "mt-5 font-sans font-light text-grey-dark" }, [
          _vm._v(
            " graduate.io is not just a website, it’s a complete service. We want to provide a personalised recruitment experience tailored to you and your needs. "
          )
        ]),
        _c("p", { staticClass: "mt-5 font-sans font-light text-grey-dark" }, [
          _vm._v(
            " We know you must be excited to kick start your journey today, so why don’t you head over and take a look at our "
          ),
          _c("a", { staticClass: "underline", attrs: { href: "/" } }, [
            _vm._v("companies")
          ]),
          _vm._v(" who are currently hiring grads today and "),
          _c("span", { staticClass: "font-medium" }, [
            _vm._v("explore your matches!")
          ])
        ]),
        _c("p", { staticClass: "mt-5 font-sans font-light text-grey-dark" }, [
          _vm._v(" Are you an "),
          _c("span", { staticClass: "font-medium" }, [_vm._v("employer")]),
          _vm._v(
            " looking to hire top talent in the graduate engineering space? You can "
          ),
          _c(
            "a",
            { staticClass: "underline", attrs: { href: "/contact-us" } },
            [_vm._v("contact us here")]
          ),
          _vm._v(" to get started. ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }